import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "gatsby";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomButton from "components/CustomButtons/CustomButton.jsx";
import ButtonESG from "components/CustomButtons/ButtonESG.jsx";
import ButtonWWD from "components/CustomButtons/ButtonWWD.jsx";
import ButtonColliery from "components/CustomButtons/ButtonColliery.jsx";
import DownloadButton from "components/CustomButtons/DownloadButton.jsx";
import SpecButton from "components/CustomButtons/SpecButton.jsx";

import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionBasics from "./Sections/SectionBasics.jsx";
import SectionNavbars from "./Sections/SectionNavbars.jsx";
import SectionTabs from "./Sections/SectionTabs.jsx";
import SectionPills from "./Sections/SectionPills.jsx";
import SectionNotifications from "./Sections/SectionNotifications.jsx";
import SectionTypography from "./Sections/SectionTypography.jsx";
import SectionJavascript from "./Sections/SectionJavascript.jsx";
import SectionCarousel from "./Sections/SectionCarousel.jsx";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.jsx";
import SectionLogin from "./Sections/SectionLogin.jsx";
import SectionExamples from "./Sections/SectionExamples.jsx";
import SectionDownload from "./Sections/SectionDownload.jsx";
import ProductSection from "./Sections/ProductSection.jsx";

import portrait from "../../assets/img/portrait2.png";
import specshome from "../../assets/img/specshome.png";
import esgimg from "../../assets/img/ESGHeader.jpg";
import wwd from "../../assets/img/wwd.jpg";
import timimage from "../../assets/img/w22.jpg";
import edimage from "../../assets/img/w15.jpg";
import andrewimage from "../../assets/img/AThomson.jpg";
import image from "../../assets/img/footimg.png";
import colliery from "../../assets/img/colliery.png";

import teamStyle from "assets/jss/material-kit-react/views/teamPage.jsx";

class TeamPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/TeamHeader.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brand}>
                <div className={classes.intro}>
                  Meet the team
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}
          <GridContainer>
          <GridItem xs={12} sm={8} md={6}>    
          <div className={classes.imagewrapteam}>
          <img alt="..." src={edimage} className={classes.homeimage} />
          </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
          <div className={classes.preheadingteam}>
          Edward Batten <br/><br/> Managing Director
                  </div>
                 
                <div className={classes.parawrap}>
                <p className={classes.paratextteam}>
                Our team leader, Ed’s main focus is sourcing land opportunities while promoting development sites and real estate solutions to occupiers. With a deep knowledge of both international corporate real estate and real-world local geographies, Ed’s experience across many different cultures and international markets are invaluable in the acquisition and evolution of undervalued developments the market seems to ignore. This ability to harness underutilised assets and opportunities has allowed him to consistently attract blue chip occupiers and institutional investors.
                <br/>
                <br/>
                Working with large multinationals, quasi-government organizations and financial institutions, he has a proven track record of creating long-term income streams for stakeholders and investors.
                </p>
              </div>
                
            </GridItem>
            </GridContainer>

            </div>  
          </div>

          <div className={classNames(classes.mainalt, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}
          <GridContainer>
          <GridItem xs={12} sm={8} md={6}>    
          <div className={classes.imagewrapteam}>
          <img alt="..." src={timimage} className={classes.homeimage} />
          </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
      
          <div className={classes.preheadingteam}>
          Tim Bailey <br/><br/> Operations and Finance Director
                  </div>
                 
                <div className={classes.parawrap}>
                <p className={classes.paratextteam}>
                Tim spent 9 years as Head of Global Sales for a UK Oil &amp; Gas service company. While based in Dubai, Tim was a key part of an initial team of three which grew into an internationally renowned industry player. Subsequent to the sale of that business in 2019, Tim relocated to the UK to aid Ed in the growth of VCD. Tim will be based in Scotland and Wales, giving the team a number of new avenues for investment opportunities. 
                <br/>
                <br/>
                Bringing to bear his substantial experience, Tim has been instrumental in the formation and growth of start-ups within various areas of commerce including farming and property development. Characterised by his flexibility and a skill for strategic planning, he exhibits an ability to excel no matter the industry in which he is employed.
                </p>
              </div>
                
            </GridItem>
            </GridContainer>

            </div>  
          </div>

          <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}
          <GridContainer>
          <GridItem xs={12} sm={8} md={6}>    
          <div className={classes.imagewrapteam}>
          <img alt="..." src={andrewimage} className={classes.homeimage} />
          </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
          <div className={classes.preheadingteam}>
          Andrew Thomson <br/><br/> Non-executive director
                  </div>
                 
                <div className={classes.parawrap}>
                <p className={classes.paratextteam}>
                In addition to being a founding partner at VCD and providing the strategic legal advice for all projects, Andrew is a Partner and Head of Real Estate at the Middle East’s largest real estate law practice, Al Tamimi. Andrew is based in Dubai and has more than 16 years of experience in the real estate sector.
                <br/>
                <br/>
                As one of the region’s most prominent real estate lawyers, Andrew represents clients across all major jurisdictions in the Middle East region. He advises investors, funds and financial institutions in sectors including retail, logistics and residential. His global experience and rolodex of decision makers provides VCD with superior access some of the world leading logistics companies and real estate investors.
                </p>
              </div>
                
            </GridItem>
            </GridContainer>

            </div>  
          </div>

        <div className={classNames(classes.mainlinks, classes.mainRaisedlinks)}>

            <div className={classes.mainwraplinks}>
            <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={wwd} className={classes.homeimage} />
          </div>
          <div className={classes.parawraplinks}>
          <ButtonWWD/>
                <p className={classes.paratextlinks}>
                Asset management, development, build, investment and equity services.
                </p>
            
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={esgimg} className={classes.homeimage} />
          </div>
          <div className={classes.parawraplinks}>
          <ButtonESG/>
                <p className={classes.paratextlinks}>
                Find out more about our team of experienced real estate professionals.
                </p>
                
              </div>
            </GridItem>

            
            </GridContainer>

            </div>   
        </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(teamStyle)(TeamPage);
